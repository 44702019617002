:root {
  --primary: #002664;
  --secondary: #00DEB6;
  --complementary: #003997;
}

p {
  margin-bottom: 0 !important;
}

.column {
  display: grid;
  height: 96%;
  min-height: 600px;
  gap: 16px;
}

.wallboard-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--complementary);
  padding: 12px;
  border-radius: 8px;
}

.wallboard-toast-container {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  z-index: 999;
}

.wallboard-toast {
  display: flex;
  background: white;
  color: black;
  width: 33vw;
  height: 80px;
  border-radius: 12px;
  overflow: hidden;
}

.xl-wallboard-heading {
  font-size: 30px;
  font-weight: 600;

  @media (min-width: 900px) {
    font-size: 34px;
  }

  @media  (min-width: 1600px ) and (min-height: 900px) {
    font-size: 40px;
  }
}

.wallboard-heading {
  font-size: 22px;
  font-weight: 600;

  @media (min-width: 1220px) and (min-height: 800px) {
    font-size: 28px;
  }

  @media (min-width: 1600px ) and (min-height: 1000px) {
    font-size: 34px;
  }
}

.wallboard-text {
  font-size: 18px;

  @media (min-width: 1220px) and (min-height: 800px) {
    font-size: 22px;
  }

  @media  (min-width: 1600px ) and (min-height: 1000px) {
    font-size: 28px;
  }
}