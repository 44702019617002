.vendor-props .storyboard
{
	width: 100%;
	overflow: auto;
	padding: 20px;
	white-space: nowrap;
	font-size: 90%;
}

.vendor-props .stage
{
	min-width: 200px;
	width: 10%;
	min-height: 300px;
	display: inline-block;
	padding-left: 20px;
	vertical-align: top;
	
}

.vendor-props .floater
{
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
}

.vendor-props .floater span
{
	background-color: black;
	color: white;
}

.vendor-props .stage h2
{
	color: white;
	background: black;
	padding: 5px;
	min-height: 40px;
	white-space: pre-wrap;
	font-size: 20pt;
	min-height: 68px;
}


.vendor-props .stage.passive h2
{
	background-color: #999;
}

.vendor-props .stage li h3
{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	font-size: 14pt;
	margin-top: 0;
}

.vendor-props .stage li p
{
	margin-bottom: 0;
}

.vendor-props .stage ul
{
	padding-left: 0;
	min-height: 150px;
}

.vendor-props .stage li
{
	list-style: none;
	padding: 10px;
	/* border: 1px solid #666; */
	border: 1px solid #aaa;
	margin: 10px 0;
	/* max-width: 200px; */
	cursor: pointer;
	position: relative;
}

.vendor-props .stage .label
{
	position: absolute;
	top: 5px;
	right: 5px;
}
