body
{
	min-height: auto !important;
}

.vendor-prop-side-view .vp-side-list
{
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	width: 250px;
	padding: 60px 20px 20px 20px;
	overflow: auto;
	background-color: #eee;
	border-right: 1px solid #aaa;
	z-index: -1;
}

.vendor-prop-side-view .vp-main-panel
{
	position: absolute;
	right: 0;
	top: 60px;
	left: 250px;
	z-index: -2;
}

/* this is only for awesome screens e.g my imac */
@media (min-width: 1768px) 
{
	.vendor-prop-side-view .vp-main-panel
	{
		left: 0;
	}
}

.vendor-prop-side-view.vendor-props .stage
{
	width: 100%;
	min-width: auto;
	padding-left: 0;
}

@media only screen and (max-width: 600px) {
	.vendor-prop-side-view.vendor-props .stage
	{
		width: 50%;
	}
}

.vendor-prop-side-view.vendor-props .stage li
{
	background-color: white;
}

.vendor-prop-side-view.vendor-props .stage li.went-for-val
{
	background-color: #5cb85c;
}

.vendor-prop-side-view.vendor-props .stage li.is-selected
{
	background-color: #fff819;
}