.row .note-pin
{
	display: none;
	cursor: pointer
}

.row .is-pinned .note-pin, 
.row:hover .note-pin
{
	display: block;
}